@import "../../../variables.scss";

.our-projects-content {
    position: relative;
    width: 100%;
}

.our-projects {
    margin-bottom: 30px;
    border-radius: 4px;
    height: 95%;
    padding: 0;
    transition: $transition;

    &:hover {
        .our-projects-img {
            img {
                transform: scale(1.1) translate(-50%, -50%);
                transition: $transition;
            }
        }
        .our-projects-content {
            background: #fff;
            box-shadow: 0px 10px 20px 0px rgba($passive-color, 0.75);
        }
    }

    &-img {
        position: relative;
        border-radius: 4px 4px 0 0;
        box-sizing: border-box;
        overflow: hidden;
        min-height: 10em;

        img {
            position: absolute;
            border-radius: 4px 4px 0 0;
            transition: $transition;
            height: auto;
            max-width: 100%;
            border: none;
            box-shadow: none;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &-content {
        border-radius: 0 0 4px 4px;
        padding: 13px 13px 24px;
        position: relative;
        transition: $transition;
        background-color: $white-color;
        min-height: 8em;
        .item-date {
            position: absolute;
            top: -20px;
            left: 35px;
            padding: 8px 15px;
            border-radius: 4px;
            font-size: 15px;
            color: $white-color;
            background-color: $primary-color;
        }
        .title {
            transition: $transition;
            color: $dark-color;
            word-break: break-word;
            font-size: 1.2em;
        }
    }
}

.our-projects-vertical {
    .our-projects {
        &-img img {
            border-radius: 4px;
        }
        &-content {
            .item-date {
                left: -45px;
            }
        }
    }
}

.our-projects {
    padding: 0 20px;
}
