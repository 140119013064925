@import "../../../variables.scss";

.animated-counter {
    &-section {
        padding: 1em 2em;
        &:nth-child(2) {
            &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                bottom: 0;
                height: 50%;
                transform: translateY(-50%);
                border-right: 1px solid $primary-color;
                border-left: 1px solid $primary-color;
            }
        }

        .row {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 3rem;
            @include gt-xs {
                font-size: 3rem;
            }
            @include gt-sm {
                font-size: 3rem;
            }
            @include gt-md {
                font-size: 3rem;
            }
            @include gt-lg {
                font-size: 3.5rem;
            }

            svg {
                color: $primary-color;
            }

            span {
                color: $primary-color;
                font-size: 0.7em;
            }

            p {
                font-size: 0.3em;
            }
        }
    }
}
