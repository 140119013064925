.bigText {
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position-y: bottom;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 18vw;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    font-weight: 800;
    -webkit-font-smoothing: antialiased;
}