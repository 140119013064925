@import "../../../variables.scss";

.contacts {
  .section-content.min-margin {
    margin-top: 0px;
  }
  &-list {
    &-item {
      a {
        display: flex;
        height: 50px;
        align-items: center;
      }
      &__img {
        margin: 0 10px;
        width: 25px;
        height: 25px;
        color: $dark-color;
        &:hover {
          color: $primary-color;
        }
      }
      &__name {
        margin: 0 !important;
        color: $dark-color;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}
