@import '../../variables.scss';

.news {
  h1 {
    float: left;
    a {
      color: $white-color;
      &:hover {
        color: $primary-color;
      }
    }
  }
  .item-date {
    float: right;
  }
  &-single .single-page-container {
    position: relative;
  }
  &-slider {
    &-img {
      display: flex;
      justify-content: center;
      img {
        width: 250px;
        height: 250px;
        object-fit: cover;
      }
    }
  }
}
.news-vertical {
  .news-card {
    position: static;
    &-content {
      position: relative;
      .item-date {
        top: -21px;
        right: 0;
        left: unset !important;
      }
    }
  }
}
.news-single {
  .item-date {
    position: static;
  }
}
