@import '../../variables.scss';

.home-page-slider {
    position: relative;
    top: 0;
    height: 70vh;
    overflow: hidden;
    background: $primary-color;
}

// https://p.bigstockphoto.com/GeFvQkBbSLaMdpKXF1Zv_bigstock-Aerial-View-Of-Blue-Lakes-And--227291596.jpg
//  https://api.volios.am/2020/6/florian-olivo-1169465-unsplash-1200x800.jpg
// https://api.volios.am/2020/6/89821242_2937223296338754_2611242177828749312_o.jpg