@import '../../../variables.scss';


.video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @include gt-xl {
        width: 100%;
    }
    &-box {
        position: relative;
        display: flex;
        align-items: center;
        overflow: hidden;
        height: 70vh;
    }
    &-overlay {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        background-color: #000;
        opacity: 0.5;
        z-index: 8;
    }
    &-slogan {
        position: absolute;
        height: auto;
        width: 100%;
        font-size: 2.5em;
        margin-top: -1.5em;
        text-align: center;
        color: #fff;
        z-index: 9;
        @include gt-xs {
            font-size: 3em;
        }
        @include gt-sm {
            font-size: 3.5em;
        }
    }
    &-sub-slogan {
        position: absolute;
        height: auto;
        width: 100%;
        margin-top: 4.5em;
        text-align: center;
        color: #fff;
        opacity: 0.75;
        z-index: 9;
        font-size: 1.25em;
        padding: 0 1em;
        @include gt-xs {
            font-size: 1.75em;
        }
        @include gt-sm {
            font-size: 2.25em;
        }
    }
}