@import "../../variables.scss";

.footer-wrap {
  background-color: $dark-color;
  color: $white-color;
}

.footer {
  padding: 75px 0 45px;
  a {
    color: $white-color;
    &:hover {
      color: $primary-color;
    }
  }
  &-social {
    list-style-position: outside;
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      margin-right: 10px;
      a {
        color: $white-color;
        font-size: 14px;
        height: 35px;
        width: 35px;
        line-height: 35px;
        background-color: #313131;
        border-radius: 50px;
        display: block;
        text-align: center;
        transition: $transition;
        &:hover {
          background-color: $primary-color;
          color: $white-color;
        }
      }
    }
  }
  &-contacts {
    &-list {
      &-item {
        font-size: 1.1em;
        svg {
          margin: 0 10px;
          color: $primary-color;
          &:hover {
            color: $white-color;
          }
        }
      }
    }
  }
}

.footer-bottom-wrap {
  padding: 30px 0px;
  border-top: 1px solid #2b2b2b;

  .copyright {
    text-align: center;
    color: #969696;
    font-size: 16px;
    padding: 0 15px;
  }

  a {
    color: $primary-color;
  }
}

.main-footer-box {
  margin-bottom: 3rem;
  li {
    margin-bottom: 15px;
    & img {
      margin-right: 15px;
    }
    a {
      font-size: 15px;
      transition: $transition;
      position: relative;
      display: flex;
      align-items: center;
      p {
        margin: 0 !important;
      }
    }
  }
  &.widget_recent_entries li {
    border-bottom: 1px solid #2c2c2c;
    margin-bottom: 15px;
    .post-date {
      display: block;
      font-size: 15px;
      color: #969696;
      margin-bottom: 10px;
    }
    a {
      font-size: 15px;
      transition: $transition;
    }
  }
  .title {
    margin-bottom: 20px;
    position: relative;
    padding-bottom: 22px;
    font-size: 24px;
    color: #ffffff;
    font-weight: 500;
    &:before {
      content: "";
      height: 3px;
      width: 50px;
      background-color: $primary-color;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 1;
    }
  }
}
