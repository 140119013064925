// e73d20 -> red
// 0000ff -> blue

$bp-xs: 576px;
$bp-sm: 768px;
$bp-md: 992px;
$bp-lg: 1200px;
$bp-xl: 1900px;

$transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);

$primary-color: #00009C;
$secondary-color: #3c99dc;
$active-color: #D41920;
$passive-color: #dfdfdf;
$background-color: #ededee; //553D67
$white-color: #ffffff;
$dark-color: #363636;
$body-color: #f7f7f7;
