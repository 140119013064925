.opinion{
    .swiper-slide{
        height: auto !important;
    }
    &-item{
        padding: 25px 15px;
        background-color: #fff;
        height:100%;
        margin: 20px;
        display: flex;
        flex-direction: column;
        &-img{
            width: 100px;
            height: 100px;
            border-radius: 50%;
            overflow: hidden;
            margin:0 auto 20px;
            img{
                width: 100px;
                height: 100px;
                object-fit: cover;
                object-position: center;
            }
        }
        &-description{
            padding-right: 40px;
            flex: 1 1 auto;
        }
        &-line{
            border-top: 2px solid #000;
            margin: 15px 0;
            width: 30%;
        }
        &-author{
            &-title{
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 0;
            }
            &-position{
                font-size: 12px;
            }
        }
    }
}