@import "../../../variables.scss";

.services {
    .row{
        padding: 0 20px;
    }
    .item {
        background-color: $white-color;
        margin: 10px 10px;
        overflow: hidden;
        border: 1px solid #C4C4C4;
        text-align: center;
        border-radius: 0px;
        cursor: pointer;
        transition: $transition;
        height: 16.5em;
        &:hover {
            box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
        }
        &-img {
            position: relative;
            overflow: hidden;
            min-height: 10em;
            img {
                width: 100%;
                height: 160px;
                object-fit: cover;
            }
        }
        &-title {
            padding: 13px 13px 24px;
            h5 {
                overflow: hidden; 
                text-align: left;
                min-height: 1.2em;
                font-size: 18px;
                font-weight: 500;
                color: $dark-color;
                margin: 0;
            }
        }
    }
}
