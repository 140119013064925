@import "../../variables.scss";

h1 {
	a {
		color: $white-color;
		&:hover {
			color: $primary-color;
		}
	}
}

