@import '../../variables.scss';
@import '../../mixins.scss';
.main-header {
  svg:not(:root).svg-inline--fa,
  svg:not(:host).svg-inline--fa {
    box-sizing: border-box;
  }
  &-block {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    z-index: 35;
    padding: 0 15px;
  }

  &.fixed .header-nav-bar {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid $dark-color;
    box-shadow: 0 -1px 10px 0 $dark-color;
    background-color: $white-color;
    padding: 0 15px;
  }

  ul {
    list-style-position: outside;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .lang-icon {
    cursor: pointer;

    &.selected {
      background-color: $primary-color !important;
      color: $white-color !important;
    }
  }

  .header-top-bar {
    border-bottom: 1px solid $primary-color;
    font-size: 12px;

    @include gt-xs {
      font-size: 13px;
    }

    @include gt-sm {
      font-size: 14px;
    }

    .header-contact {
      display: none;

      @include gt-md {
        display: block;
      }

      li {
        font-family: 'Rubik', sans-serif;
        display: inline-block;
        margin: 0;
        margin-right: 30px;
        position: relative;
        transition: $transition;

        &:last-child {
          margin-right: 0;
        }

        svg {
          font-size: 1.1em;
          margin-right: 10px;
          color: $dark-color;
        }

        .lang-icon {
          color: $dark-color;
        }
      }
    }

    .header-social {
      min-height: 45px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      li {
        display: inline-block;
        margin: 0 10px;

        .lang-icon {
          height: 30px;
          width: 30px;
          line-height: 30px;
          border-radius: 5px;
          display: block;
          background-color: transparent;
          transition: $transition;
          text-align: center;
          color: $dark-color;

          &:hover {
            background-color: $primary-color;
            color: $white-color;
          }
        }
      }

      .divider {
        height: 75%;
        width: 1px;
        margin: 0 10px;
        background-color: $dark-color;
      }
    }
  }

  .header-nav-bar {
    display: flex;
    background-color: $white-color;
    z-index: 100;
    font-size: 12px;
    align-items: center;
    background: transparent;

    @include gt-lg {
      font-size: 14px;
    }

    .align-items-center {
      display: flex !important;
      align-items: center !important;

      .logo-area {
        max-width: 120px;
      }

      .main-navigation-area {
        display: flex;
        align-items: center;
        margin-left: 20px;

        .main-navigation {
          flex: 1;
          display: block;
          display: flex;
          justify-content: flex-end;

          nav {
            display: block;

            // li {
            //     display: inline-block;
            //     position: relative;

            // }
          }
        }
      }
    }
  }
}

.nav {
  // height: 70px;
  line-height: 70px;
}

.img-fluid {
  margin-top: 20px;
}
.dropdown-menu {
  line-height: 2;
}
.dropdown-item:hover {
  cursor: pointer;
  user-select: none;
}

.btn:focus {
  outline: none;
  box-shadow: unset;
}
.contact-info {
  line-height: 2;
  font-size: 11px;
}
.contact-info span img {
  width: 18px;
  height: 18px;
}
.nav-link.active {
  color: $dark-color;
  text-decoration: underline;
  text-underline-offset: 5px;
  text-decoration-thickness: 2px;
  text-decoration-color: #0000cd;
}
.logo {
  &-img {
    width: 180px;
    &-main {
      width: 100%;
      max-width: 360px;
    }
  }

  &-text {
    font-size: 1.15em;
    font-weight: bold;
    text-transform: uppercase;
    margin-left: 10px;

    &.sidebar {
      font-size: 1.5em;
      margin-left: 0;
      text-align: center;
      display: block;
      width: 100%;
    }
  }
}

.desktop-nav {
  display: none;

  @include gt-md {
    display: block;
  }

  .menu {
    li {
      display: inline-block;
      position: relative;

      .nav-link {
        position: relative;
        display: block;
        padding: 0 0.9vw;
        font-weight: bold;
        font-style: normal;
        text-transform: uppercase;
        text-decoration: none;
        color: $dark-color;
        transition: $transition;

        &.active {
          box-sizing: border-box;
          color: $primary-color;
          background-color: rgba(0, 0, 255, 0.05);
          box-shadow: 0 -2px 0 0 $primary-color inset;
        }

        &:hover {
          color: $primary-color;
          box-shadow: 0 -4px 0 0 $primary-color inset;
        }
      }
    }
  }
}

.mobile-nav {
  display: flex;
  justify-content: space-between;
  

  @include gt-md {
    display: none;
  }

  .nav-link {
    height: 3em;
    line-height: 3em;
    padding: 0;
    font-size: 1.1em;
  }

  .hamburger-icon {
    width: 70px;
    height: 70px;
    padding: 25px;
    float: right;
    cursor: pointer;

    @include gt-xs {
      padding: 24px;
    }

    @include gt-md {
      padding: 23px;
    }
  }

  .close-icon {
    width: 70px;
    height: 70px;
    padding: 22px;
    position: absolute;
    top: 0;
    right: 0;
    color: #0e6ec9;
    cursor: pointer;
  }

  & > nav > {
    ul {
      float: left;
      margin: 0 15px;
    }
  }

  &-menu {
    position: fixed;
    top: 0;
    right: -300px;
    width: 300px;
    height: 100%;
    z-index: 9999;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 15px;
    background: #fff;
    visibility: hidden;
    opacity: 0;
    transition: $transition;

    &.open {
      right: 0;
      visibility: visible;
      opacity: 1;
    }
  }

  & > .logo {
    position: absolute;
    left: calc(50% - 90px);
  }

  &-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(34, 34, 34, 0.7);
    z-index: 1000;
    visibility: hidden;
    opacity: 0;

    &.open {
      visibility: visible;
      opacity: 1;
      transition: $transition;
    }
  }

  .br {
    border-bottom: 1px solid #0e6ec9;
    float: left;
    margin: 15px 0;
    width: 100%;
  }

  .mobile-contacts {
    float: left;

    ul {
      flex-direction: column;
    }

    li {
      width: 240px;
      height: 50px;
      line-height: 50px;

      svg {
        margin-right: 10px;
      }
    }
  }
}
