.loader
    position: absolute
    top: 50%
    width: 100%
    height: 100%
    left: 50%
    right: 0
    display: flex
    transform: translate(-50%, -50%)
    justify-content: center
    align-items: center
    z-index: 9
    color: $primary-color