// e73d20 -> red
// 0000ff -> blue
@import './variables.scss';
@import './loader';

// // Colors
// $bg-color: $white-color;
// $dot-color: rgba($primary-color, 0.5);

// // Dimensions
// $dot-size: 1px;
// $dot-space: 20px;

// background: linear-gradient(90deg, $bg-color ($dot-space - $dot-size), transparent 1%) center,
//     linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center, $dot-color;
// background-size: $dot-space $dot-space;

body {
  font-family: 'Open Sans', sans-serif;
  transition: $transition;
  background-color: $body-color;
  overflow: hidden;
  overflow-y: auto;
}

ul {
  list-style-type: none;
  font-family: Roboto, sans-serif;
  font-style: normal;
  padding: 0;
  margin: 0;
}

a {
  color: $dark-color;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: $primary-color;
  }
}

@import './mixins.scss';
@import './variables.scss';
@import './components/main/main.scss';
@import './components/header/header.scss';
@import './components/home/home.scss';
@import './components/modules/animated-numbers/animated-counter.scss';
@import './components/modules/cta/cta.scss';
@import './components/footer/footer.scss';
@import './components/modules/news/news.scss';
@import './components/modules/projects/projects.scss';
@import './components/modules/services/services.scss';
@import './components/modules/news/news.scss';
@import './components/modules/video/video.scss';
@import './components/modules/wchus/wchus.scss';
@import './components/section/section.scss';
@import './components/services/services.scss';
@import './components/projects/projects.scss';
@import './components/news/news.scss';
@import './components/join-us/joinUs.scss';
@import './components/about-us/aboutUs.scss';
@import './components/modules/history/history.scss';
@import './components/modules/mission/mission.scss';
@import './components/modules/license/license.scss';
@import './components/modules/team/team.scss';
@import './components/modules/contacts/contacts.scss';
@import './components/modules/clients/clients.scss';
@import './components/modules/partners/partners.scss';

.single-page-container {
  padding: 1em 2.5em;
  img {
    max-width: 100%;
  }
}

.pagination {
  &-ul {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    .page {
      &-item {
        padding: 3px 0;
        display: inline-block;
        cursor: pointer;
      }
      &-link {
        position: relative;
        display: block;
        padding: 0.5rem 0.75rem;
        margin-left: -1px;
        line-height: 1.25;
        color: #007bff;
        background-color: #fff;
        border: 1px solid #dee2e6;
        &:hover {
          background-color: #e9ecef;
          border-color: #dee2e6;
        }
      }
    }
  }
  &-nav {
    width: 100%;
    display: flex;
    justify-content: center;
    .selected {
      background-color: #e9ecef;
    }
  }
}

.page {
  &-top-img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      filter: brightness(0.6) grayscale(30%);
      @include gt-md {
        width: 100%;
      }
    }
  }
  &-title {
    color: #fff;
    h1 {
      font-size: 2em;

      @include gt-xs {
        font-size: 2.1em;
      }

      @include gt-sm {
        font-size: 2.2em;
      }

      @include gt-md {
        font-size: 2.3em;
      }

      @include gt-lg {
        font-size: 2.5em;
      }
    }
  }
}

.dropdown-list {
  display: flex;
  align-items: center;
  li {
    flex: 0 0 33.33333333%;
  }

  &-item {
    width: 100%;
    border-radius: 10px;
    height: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    &.active {
      background-color: $primary-color;
      color: #fff;
    }
  }
}
