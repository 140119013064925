@import "../../variables.scss";

.bgImage {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
}
.bgImage::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000096;
}
.section {
  display: block;
  color: "#ffffff";
  position: relative;
  margin-right: auto;
  margin-left: auto;
  max-width: 1140px;
  padding: 10px;
  @include gt-xs {
    padding: 15px;
  }
  @include gt-sm {
    padding: 20px;
  }
  @include gt-md {
    padding: 25px;
  }
  @include gt-lg {
    padding: 30px;
  }

  &.ruller {
    background-image: url(//scale.png);
    background-repeat: repeat-x;
  }

  &.full-width {
    margin: 0;
    max-width: none;
    background-color: $dark-color;
    &.section-small {
      padding: 40px 20px;
      min-height: 0;
      @include gt-xs {
        padding: 40px 20px;
      }
      @include gt-sm {
        padding: 45px 25px;
      }
      @include gt-md {
        padding: 50px 30px;
      }
      @include gt-lg {
        padding: 60px 40px;
      }
    }
  }
  &.white-background {
    background-color: $white-color;
    color: $dark-color;
  }
  &-big {
    min-height: 400px;
    padding: 50px 0 50px 0;
    @include gt-md {
      padding: 100px 0 50px 0;
    }
  }
  &-small {
    min-height: 200px;
    padding-top: 50px;
    padding-bottom: 25px;
    .item-btn {
      position: relative;
      overflow: hidden;
      border-radius: 4px;
      font-size: 18px;
      font-weight: 500;
      color: $white-color;
      background-color: rgba($white-color, 0.1);
      padding: 15px 30px;
      transition: $transition;
      &:hover {
        background-color: $white-color;
        color: $primary-color;
        span {
          &:before {
            height: 100%;
            transition-delay: 0.6s;
          }
          &:after {
            height: 100%;
            transition-delay: 0.2s;
          }
        }
        &:before {
          width: 100%;
          transition-delay: 0.4s;
        }
        &:after {
          width: 100%;
          transition-delay: 0s;
        }
      }
      &:before {
        content: "";
        width: 0;
        height: 2px;
        position: absolute;
        transition: $transition;
        background: $primary-color;
        right: 0;
        top: 0;
        transition-delay: 0.2s;
      }
      &:after {
        content: "";
        width: 0;
        height: 2px;
        position: absolute;
        transition: $transition;
        background: $primary-color;
        left: 0;
        bottom: 0;
        transition-delay: 0.6s;
      }
      span {
        &:before {
          content: "";
          width: 2px;
          height: 0;
          position: absolute;
          -webkit-transition: $transition;
          transition: $transition;
          background: $primary-color;
          -webkit-transition-delay: 0s;
          transition-delay: 0s;
          left: 0;
          top: 0;
        }
        &:after {
          content: "";
          width: 2px;
          height: 0;
          position: absolute;
          transition: $transition;
          background: $primary-color;
          transition-delay: 0.4s;
          right: 0;
          bottom: 0;
        }
        svg {
          margin-left: 20px;
          font-size: 18px;
        }
      }
    }
  }
  &-title {
    text-align: center;
    width: 100%;
    font-weight: bold;
    &.underline {
      position: relative;
      display: inline-block;
      margin-bottom: 12px;
      padding-bottom: 15px;
      text-align: left;
      font-weight: normal;
      &:before {
        content: "";
        height: 4px;
        width: 50px;
        background-color: $primary-color;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
      }
    }
  }
  &-subtitle {
    text-align: center;
    width: 100%;
  }
  &-content {
    margin-top: 50px;
    @include gt-md {
      margin-top: 100px;
    }

    &.min-margin {
      margin-top: 50px;
    }
    &.margin-0 {
      margin-top: 10px;
    }
  }

  .content-right,
  .content-left {
    display: flex;
    align-items: center;
  }
  .content-left {
    margin-bottom: 30px;
    @include gt-md {
      margin-bottom: 0;
    }
    h3 {
      color: #ffffff;
      margin-bottom: 0px;
      line-height: 1.3;
    }
  }
}
