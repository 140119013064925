@import "../../../variables.scss";

@keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 #ffc211;
        box-shadow: 0 0 0 0 #ffc211;
    }
    40% {
        -webkit-box-shadow: 0 0 0 15px rgba(255, 194, 17, 0);
        box-shadow: 0 0 0 15px rgba(255, 194, 17, 0);
    }
    70% {
        -webkit-box-shadow: 0 0 0 15px rgba(255, 194, 17, 0);
        box-shadow: 0 0 0 15px rgba(255, 194, 17, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 194, 17, 0);
        box-shadow: 0 0 0 0 rgba(255, 194, 17, 0);
    }
}

.team {
    .item {
        padding: 30px 30px 25px;
        border-radius: 4px;
        text-align: center;
        margin-bottom: 10px;
        transition: $transition;
        // &:hover .item-img-wrapper img:after {
        //     visibility: visible;
        //     opacity: 1;
        //     animation: pulse 2s infinite;
        // }
        &-img-wrapper {
            transition: $transition;
            overflow: hidden;
            img {
                border-radius: 20%;
                width: 160px;
                height: 160px;
                margin: 0 auto;
                &:after {
                    content: "";
                    height: 100%;
                    width: 100%;
                    background-color: rgba(255, 194, 17, 0.85);
                    border-radius: 50%;
                    bottom: 0;
                    left: 0;
                    z-index: 1;
                    opacity: 0;
                    visibility: hidden;
                    position: absolute;
                    transition: $transition;
                }
            }
        }
        &-content {
            margin: 0 0 23px;
            text-align: center;
            .subtitle {
                color: $primary-color;
            }
        }
    }
}
