@import '../../../variables.scss';

.wchus {
	&-list {
		font-size: 1.2em;
		padding: 1em 2em;

		ul {
			list-style: disc;
		}

		svg {
			margin: 0 20px;
		}

		&-item {
			display: flex;
			align-items: center;
			padding: 10px 20px;
		}
		img {
			max-width: 100%;
			padding: 1em;
			height: 100%;
			border-radius: 4px;
			
			@include gt-sm {
				max-width: 50%;
			}
		}
	}

}
