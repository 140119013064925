
@mixin gt-xs {
  @media screen and (min-width: $bp-xs) {
    @content
  }
}

@mixin lt-xs {
  @media screen and (max-width: $bp-xs){
    @content
  }
}

@mixin gt-sm {
  @media screen and (min-width: $bp-sm){
    @content
  }
}
@mixin lt-sm {
  @media screen and (max-width: $bp-sm){
    @content
  }
}

@mixin gt-md {
  @media screen and (min-width: $bp-md){
    @content
  }
}
@mixin lt-md {
  @media screen and (max-width: $bp-md){
    @content
  }
}

@mixin gt-lg {
  @media screen and (min-width: $bp-lg){
    @content
  }
}
@mixin lt-lg {
  @media screen and (max-width: $bp-lg){
    @content
  }
}

@mixin gt-xl {
  @media screen and (min-width: $bp-xl){
    @content
  }
}
@mixin lt-xl {
  @media screen and (max-width: $bp-xl){
    @content
  }
}
    
@mixin gt-custom($width) {
  @media screen and (min-width: $width) {
    @content
  }
}

@mixin lt-custom($width) {
  @media screen and (max-width: $width) {
    @content
  }
}
