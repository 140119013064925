@import "../../../variables.scss";

.license {
    .list-item {
        height: 35px;
        line-height: 35px;
        display: flex;
        align-items: center;
        svg {
            margin-right: 20px;
        }
    }
    &-slide {
        cursor: pointer;
    }
}
