.clients {
    img {
        width: 100%;
        max-width: 180px;
        max-height: 180px;
        padding: 0 5px;
        margin: 0 auto;
        @include gt-xs {
            padding: 0 7.5px;
        }
        @include gt-sm {
            padding: 0 10px;
        }
        @include gt-md {
            padding: 0 12.5px;
        }
        @include gt-lg {
            padding: 0 15px;
        }
    }
}