@import '../../../variables.scss';

.news-content {
  position: relative;
  width: 100%;
}
.news-arrow-next {
  right: -30px !important;
}
.news-arrow-prev {
  left: -30px !important;
}
.item-date {
  position: absolute;
  top: 21px;
  left: 0;
  padding: 8px 15px;
  border-radius: 4px;
  color: $white-color;
  background-color: $dark-color;
  font-size: 0.8em;
}
.news-card {
  margin-bottom: 30px;
  border-radius: 4px;
  height: 95%;
  padding: 0 20px;
  transition: $transition;
  position: relative;

  &:hover {
    .news-card-img {
      img {
        transform: scale(1.06);
        transition: $transition;
      }
    }
    .news-card-content {
      background: #fff;
      box-shadow: 0px 15px 20px 0px rgba($passive-color, 0.9);
    }
  }

  &-img {
    img {
      transition: $transition;
      height: 200px;
      box-shadow: none;
      width: 100%;
      object-fit: cover;
      // @include gt-sm {
      //     width: auto;
      //     height: 100%;
      // }
    }
  }

  &.row > a {
    display: flex;
  }

  &-content {
    border-radius: 0 0 4px 4px;
    padding: 13px 13px 24px;
    transition: $transition;
    background-color: $white-color;
    min-height: 8em;
    // box-shadow: 0px 5px 10px 0px rgba($passive-color, 0.75);
    border: 1px solid $passive-color;
    .item-date {
      position: absolute;
      top: 15px;
      left: 0;
      padding: 8px 15px;
      border-radius: 4px;
      color: $white-color;
      background-color: $dark-color;
    }
    .title {
      transition: $transition;
      color: $dark-color;
      word-break: break-word;
      font-size: 1.2em;
    }
  }
}

.news-vertical {
  .news-card {
    &-img img {
      border-radius: 4px;
    }
    &-content {
      @include gt-sm {
        .item-date {
          left: -50px !important;
          right: auto !important;
          float: right;
          top: -10px;
        }
      }
    }
  }
}
